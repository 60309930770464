var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4",
          attrs: {
            src: require("@/assets/images/pages/not-authorized.png"),
            alt: "graphic-not-authorized",
          },
        }),
        _c(
          "h1",
          {
            staticClass:
              "sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color",
          },
          [_vm._v("\n          Seu usuário está bloqueado!\n        ")]
        ),
        _vm._m(0),
        _c(
          "vs-button",
          { staticClass: "mt-8", attrs: { size: "large", to: "/logout" } },
          [_vm._v("Voltar ao login.")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("blockquote", [
      _c("p", [_c("strong", [_vm._v("Atenção!")])]),
      _c("p", [_vm._v("Seu usuário foi bloqueado pelo administrador do CFC.")]),
      _c("p", [
        _vm._v(
          "Entre em contato com o administrador do CFC e solicite desbloqueio."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }